
const Reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_POINTS':
      return {
            ...state,
            points: action.payload,
            filteredPoints: action.payload,
        };
    case 'UPDATE_HOTELS':
      return {
            ...state,
            hotels: action.payload,
        };
    case 'FILTER_SAVED':
          return {
            ...state,
            filteredSaved: action.payload,
        };
    case 'FILTER_HOTEL':
          return {
            ...state,
            filteredHotels: action.payload,
        };
    case 'FILTER_EVENT':
        return {
                ...state,
                filteredEvents: action.payload,
        };
    case 'FILTER_EAT':
          return {
                  ...state,
                  filteredEat: action.payload,
          };
    case 'RANKING_ITEMS':
          return {
                  ...state,
                  filteredRanking: action.payload,
          };
    case 'FILTER_EAT_LENGTH':
            return {
                    ...state,
                    filteredEatLength: action.payload,
            };
    case 'FILTER_WLAN':
          return {
                  ...state,
                  filteredWlan: action.payload,
          };
    case 'SELECTED_POINT':
        return {
            ...state,
            selectedPoint: action.payload,
        };
    case 'SELECTED_SPOT':
        return {
            ...state,
            selectedSpot: action.payload,
        };
    case 'FILTER_FAVORITES':
        return {
            ...state,
            favoritesList: action.payload
        };
    case 'FILTER_FAVORITES_LENGTH':
          return {
              ...state,
              favoritesListlength: action.payload
          };
    case 'FAVORITE_POINTS':
            return {
                ...state,
                favoritePoints: action.payload
            };
    case 'FILTER_SEARCH':
          return {
              ...state,
              searchList: action.payload
          };
    case 'YOUR_LOCATION_COORDINATES':
          return{
            ...state,
            yourLocationCoordinates: action.payload,
        };
    case 'SPOT_COORDINATES':
          return{
            ...state,
            spotCoordinates: action.payload,
        };
    case 'YOUR_LOCATION_ADDRESS':
          return{
            ...state,
            yourLocationAddress: action.payload,
        };
    case 'SELECT_CATEGEORY':
          return {
            ...state,
            selectCategory: action.payload,
          };
    case 'FILTER_DISTANCE':
            return {
              ...state,
              distanceFilter: action.payload,
          };
    case 'MAX_DISTANCE':
            return {
              ...state,
              maxDistance: action.payload,
          };
    case 'SELECTED_USER':
            return {
                ...state,
                selectedUser: action.payload,
            };
    case 'MARATHON':
            return {
              ...state,
              marathon: action.payload,
          };
    case 'SET_MARKETING_CONSENT':
            return {
              ...state,
              marketing: action.payload,
          };
    default:
      throw new Error('No matched action!');
  };
};

export default Reducer;

const applyFilter = (points, area) => {
  let filteredPoints = points

  if (area === "clear") {
    points = points;
  }
  // else if (area === "saved"){
  //   filteredPoints = filteredPoints.filter((list) => list.properties.favorite === true)
  // }
  else {
    filteredPoints = filteredPoints.filter(function(item) { 
      return area.indexOf(item.properties.tags[0]) !== -1
    })
  }

  return filteredPoints;
};

let aryList = []

const addOnlyTwo = (id) => { 
aryList.push(id)
if(aryList.length > 2){
    aryList.shift()
}
return aryList
}