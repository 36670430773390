import React from "react";
import { IntlProvider } from 'gatsby-plugin-intl';
import ContextProvider from './src/components/context/contextProvider';

export const wrapPageElement = ({ element }) => {
  return (
    <IntlProvider>
    <ContextProvider>
      
        <div className="absolute top-20 right-2 z-20 md:right-5">
        </div>
        {element}
      
    </ContextProvider>
    </IntlProvider>
  );
};